/** @jsx jsx */
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import { jsx, css } from "@emotion/core"
import Sidebar from "../components/Sidebar"
import SEO from "../components/seo"

const ArticleTemplate = ({ data }) => {

  const createDescription = value => {
    return value.replace(/<[^>]+>/g, "").slice(0, 160) + "..."
  }

  return (
    <Layout>
      <SEO title={data.strapiArticle.title}
           image={data.strapiArticle.image ? data.strapiArticle.image.childImageSharp.fluid.src : null}
           description={data.strapiArticle.description ? data.strapiArticle.description :createDescription(data.strapiArticle.content)}
      />

      <Section css={{ marginTop: "60px" }} className="py-5">
        <Container className="content">
          <h1 className="font-weight-semi-bold">{data.strapiArticle.title}</h1>
        </Container>
      </Section>
      <Section className="pt-0">
        <Container>
          <Row>
            <Col lg={8}>
              <div
                css={css`
                border-radius: 4px;
                overflow: hidden;
              `}
                className="mb-4"
              >
                <Img fluid={data.strapiArticle.image.childImageSharp.fluid} />
              </div>

              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: data.strapiArticle.content }}
              />
            </Col>
            <Col lg={4}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleBySlug($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      content
      description
      image {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
